<template>
  <div class="container-medicalView">
    <div class="header-medicalView">
      <div class="information-count">
        <img
          id="img-container-userIcon"
          src="../../assets/empty-user-icon.png"
          alt="user"
        >
        <div class="right-information">
          <label>{{ role }}</label>
          <label>{{ countInfo.firstName }} {{ countInfo.lastName }}</label>
          <label>{{ countInfo.email }}</label>
        </div>
      </div>
      <h2 id="fecha"> {{ $dateString(dateDateStr) }}</h2>
    </div>
    <div class="content-cards">
      <div v-if="roleId === 'MD' || roleId === 'ADMIN' || roleId === 'OPTO' || roleId === 'FD'">
        <div class="carrete-cards">
          <Card typeSearch="gabinete" title="ESTUDIOS DE GABINETE"/>
        </div>
      </div>
      <div
        class="carrete-cards"
        v-if="roleId === 'FD' || roleId === 'ADMIN' || roleId === 'OPTO' || roleId === 'MD'"
      >
        <Card typeSearch="consultaGeneral" title="AGENDA DE CONSULTA GENERAL"/>
      </div>
    </div>
    <div class="content-cards">
      <div
        class="carrete-cards"
        v-if="roleId === 'OPTO' || roleId === 'MD' || roleId === 'ADMIN' || roleId === 'FD'">
        <Card typeSearch="especialidad" title="AGENDA DE ESPECIALIDADES"/>
      </div>
    </div>
    <div class="content-cards">
      <div style="display: flex;">
        <div v-if="roleId === 'TS' || roleId === 'ADMIN'">
          <div class="carrete-cards">
            <Card typeSearch="trabajoSocial" title="TABAJO SOCIAL"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Card from '../../components/home/card';

const moment = require('moment');

export default {
  data() {
    return {
      dateDateStr: null,
      roleId: null,
      countInfo: null,
      role: null,
    };
  },
  components: {
    Card,
  },
  mounted() {
    const date = new Date();
    this.dateDateStr = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
    const roleIdLocalStorage = JSON.parse(localStorage.getItem('vuex'));
    this.countInfo = roleIdLocalStorage.auth.user;
    if (this.countInfo.role.name === 'RECEPCION') {
      this.role = 'RECEPCIÓN';
    } else {
      this.role = this.countInfo.role.name;
    }
    this.roleId = roleIdLocalStorage.auth.user.roleId;
  },
};
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

.container-medicalView {
  padding: 10px;
  font-family: 'Poppins', sans-serif;
}
.header-medicalView {
  display: flex;
  justify-content: space-between;
  #fecha {
    color: #8F70C7;
  }
}
.information-count {
  display: flex;
  align-items: center;
  gap: 1rem;
  .right-information {
    display: flex;
    flex-direction: column;
    label {
      color: rgb(65, 65, 65);
      font-weight: bold;
      font-size: 14px;
    }
  }
}
#img-container-userIcon {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}
.content-cards {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  h1 {
    color: #8F70C7
  }
  .carrete-cards {
    padding: 15px;
  }
  width: 100%;
}
</style>
