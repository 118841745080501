<template>
<div>
  <h1 style="color: #8F70C7;" v-if="cards.length > 0" >{{ title }}</h1>
  <div class="container-card-home">
    <div v-for="(card, idx) in cards" :key="idx">
      <Tooltip
        placement="top"
        v-if="enableTooltip[card.subject || card.name]">
        <div slot="content">
          <label v-if="card.subject === 'Médico'">
            <label v-for="(procedure, idx) in procedures" :key="idx">
              {{ procedure.name }} : {{ procedure.Total }} <br>
            </label>
          </label>
          <label v-if="enableTooltip[card.name]">
            <label v-for="(procedure, idx) in procedures[card.name]" :key="idx">
              {{ procedure.name }} : {{ procedure.Total }} <br>
            </label>
          </label>
        </div>
        <div class="card-home" v-if="card.total > 0"  @click="selectedCard(idx)">
          <label v-if="card.name">{{ card.name }}</label>
          <label v-else-if="card.type">{{ card.subject }}</label>
          <label v-else>Citas</label>
          <label style="font-size: 52px;">{{ card.total === 0 ? '0': card.total }}</label>
        </div>
      </Tooltip>
      <div v-else>
        <div class="card-home" v-if="card.total > 0"  @click="selectedCard(idx)">
          <label v-if="card.name">{{ card.name }}</label>
          <label v-else-if="card.type">{{ card.subject }}</label>
          <label v-else>Citas</label>
          <label style="font-size: 52px;">{{ card.total === 0 ? '0': card.total }}</label>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { Tooltip } from 'element-ui';
import router from '../../router/index';

const moment = require('moment');

export default {
  props: {
    typeSearch: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: 'especialidad',
    },
  },
  data() {
    return {
      cardSelected: null,
      cards: [],
      procedures: [],
      cardIdRoute: null,
      enableTooltip: {
        Córnea: true,
        Glaucoma: true,
        Médico: true,
      },
    };
  },
  components: {
    Tooltip,
  },
  methods: {
    clearLocalStorage() {
      localStorage.removeItem('currentRow');
      localStorage.removeItem('appointmentsTypeSelected');
    },
    async getAllSpecialities() {
      try {
        const date = new Date();
        const dateFotmat = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
        const request = await this.axios(`patient/specialty/count/${dateFotmat}?typeSearch=${this.typeSearch}`);
        const { data } = request;
        if (request) {
          this.cards.push(...data);
        }
      } catch (error) {
        // console.log(error);
      }
    },
    selectedCard(idx) {
      this.clearLocalStorage();
      const { subject } = this.cards[idx];

      if (subject === 'Médico') {
        this.cardIdRoute = 100;
        router.push({ path: '/admin/evaluations/card/100' });
      } else if (this.typeSearch === 'trabajoSocial') {
        this.cardSelected = this.cards[idx].userId;
        this.cardIdRoute = 200;
        router.push({ path: `/admin/evaluations/card/${this.cardIdRoute}/trabajo-social` });
      } else if (this.typeSearch === 'gabinete') {
        this.cardSelected = this.cards[idx].userId;
        this.cardIdRoute = 300;
        router.push({ path: `/admin/evaluations/card/${this.cardIdRoute}` });
      }

      if (this.cards[idx].id !== undefined) {
        router.push({ path: `/admin/evaluations/card/${this.cards[idx].id}` });
      }
    },
    async getAllProceduresAppointments() {
      try {
        const date = moment(new Date()).format('YYYY-MM-DD');
        if (this.typeSearch === 'consultaGeneral') {
          const res = await this.axios(`patient/appointment/procedures/Médico/${date}/`);
          this.procedures = res.data;
        } else {
          // LLAMADA PARA OBTENER PROCEDIMIENTOS DE ESPECIALIDADES
          const res = await this.axios(`patient/appointment/procedures/${this.typeSearch}/${date}/1`);
          const res2 = await this.axios(`patient/appointment/procedures/${this.typeSearch}/${date}/2`);
          this.procedures = { Córnea: res.data, Glaucoma: res2.data };
        }
      } catch (error) {
        // console.log(error);
      }
    },
  },
  async created() {
    await this.getAllSpecialities();
    await this.getAllProceduresAppointments();
  },
};
</script>

<style lang="scss" scoped>
.container-card-home {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}
.card-home {
  background-color: #8F70C7;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 12px;
  width: 175px;
  height: 168px;
  padding: 25px;
  color: #fff;
  .icon-circle {
    border-radius: 50%;
    width: 45px;
    height: 45px;
    background-color: #fff;
  }
  label {
    padding: 0px 5px;
    width: 175px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 18px;
  }
}
</style>
